@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes loadingIn {
  from {
    transform: translate3d(0, 100vh, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes loadingOut {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  99% {
    transform: translate3d(0, -100vh, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, -1000vh, 0);
    opacity: 0;
  }
}

@keyframes floating {
  0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(0.5%, 1.5%, 0);
  }

  50% {
    transform: translate3d(2.5%, 3%, 0);
  }

  75% {
    transform: translate3d(0.7%, 1.5%, 0);
  }

  100% {
    transform: translate3d(0%, 0%, 0);
  }
}

@keyframes floating-smoth-rotate {
  0% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }

  25% {
    transform: translate3d(0.3%, 0.5%, 0) rotate(13deg);
  }

  50% {
    transform: translate3d(0.7%, 0.1%, 0) rotate(17deg);
  }

  75% {
    transform: translate3d(0.2%, 0.6%, 0) rotate(8deg);
  }

  100% {
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
}

@keyframes floating-smoth {
  0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(0.3%, 0.5%, 0);
  }

  50% {
    transform: translate3d(0.7%, 0.1%, 0);
  }

  75% {
    transform: translate3d(0.2%, 0.6%, 0);
  }

  100% {
    transform: translate3d(0%, 0%, 0);
  }
}

@keyframes bost {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0.9;
  }

  25% {
    transform: translate3d(0.5%, 1.5%, 0);
    opacity: 0.7;
  }

  50% {
    transform: translate3d(0.5%, 2.5%, 0);
    opacity: 0.4;
  }

  75% {
    transform: translate3d(0.1%, 1.5%, 0);
    opacity: 0.7;
  }

  100% {
    transform: translate3d(0%, 0%, 0);
    opacity: 0.9;
  }
}

@keyframes bost-no-transparency {
  0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(3%, -2.4%, 0);
  }

  50% {
    transform: translate3d(4%, -2.7%, 0);
  }

  75% {
    transform: translate3d(3%, -2.4%, 0);
  }

  100% {
    transform: translate3d(0%, 0%, 0);
  }
}
