// // // // TYPOGRAPHY

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap");

@font-face {
  font-family: "Kayak Sans";
  src: url("/assets/fonts/kayak-sans/kayak-sans-bold.woff") format("woff"),
    url("/assets/fonts/kayak-sans/kayak-sans-bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kayak Sans";
  src: url("/assets/fonts/kayak-sans/kayak-sans-regular.woff") format("woff"),
    url("/assets/fonts/kayak-sans/kayak-sans-regular.woff2") format("woff2"),
    url("/assets/fonts/kayak-sans/kayak-sans-regular.otf") format("otf"),
    url("/assets/fonts/kayak-sans/kayak-sans-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kayak Sans";
  src: url("/assets/fonts/kayak-sans/kayak-sans-light.woff") format("woff"),
    url("/assets/fonts/kayak-sans/kayak-sans-light.woff2") format("woff2"),
    url("/assets/fonts/kayak-sans/kayak-sans-light.otf") format("otf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "Roboto Mono", monospace;

  p,
  form {
    font-family: "Kayak Sans";
  }
}
