// // // // MIXINS

// media queries
@mixin breakpoint($point) {
  @if $point == desktop {
    @media (max-width: 1600px) {
      @content;
    }
  } @else if $point == tablet {
    @media (max-width: 1250px) {
      @content;
    }
  } @else if $point == tablet-small {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: 650px) {
      @content;
    }
  } @else if $point == notebook-height {
    @media (max-height: 768px) {
      @content;
    }
  }
}

// Convierto px a rem
// EJ: font-size: size(20);
$base-size: 16;
@function size($target, $context: $base-size) {
  @return ($target / $context) * 1rem;
}

@mixin --l-wrapper() {
  margin: 0 auto;
  width: 100%;
  max-width: size(1260);
}

@mixin --l-wrapper-m() {
  margin: 0 auto;
  width: 100%;
  max-width: size(1120);
}

@mixin animation($name, $duration: 1s, $timming: ease-out) {
  animation-name: $name;
  animation-duration: $duration;
  animation-timing-function: $timming;
  animation-fill-mode: forwards;
}

@mixin translateY($offset: 0, $transitionTime: 0.1s, $timming: ease-out) {
  transform: translate3d(0, calc(var(--scroll-Y) * #{$offset}), 0);
  transition: transform $transitionTime $timming;
  will-change: transform;
}

@mixin translateX($offset: 0, $transitionTime: 0.1s, $timming: ease-out) {
  transform: translate3d(calc(var(--scroll-Y) * #{$offset}), 0, 0);
  transition: transform $transitionTime $timming;
  will-change: transform;
}

@mixin truncate-text-multi-line($font-size, $line-height, $lines-to-show) {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-to-show;
  display: -webkit-box;
  font-size: $font-size * 1px;
  height: $font-size * $line-height * $lines-to-show * 1px;
  line-height: $line-height;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin coara-button($font-size: 20px, $border-width: 3px, $border-color: var(--light-color-2), $line-height: 1.4) {
  font-size: $font-size;
  text-transform: uppercase;
  font-weight: 700;
  line-height: $line-height;
  width: fit-content;
  display: block;
  border: $border-width solid $border-color;
  padding: var(--padding) calc(var(--padding) * 2);
  color: $border-color;
  margin: auto;
  transition: border-color 0.15s ease-out;
  &:after {
    content: " ";
    position: relative;
    display: block;
    height: $border-width;
    width: 100%;
    background-color: $border-color;
    transform: scaleX(0);
    left: inherit;
    right: inherit;
    bottom: inherit;
    transition: transform 0.3s ease-out;
    transition-delay: 0.15s;
  }
  &:hover {
    border-color: transparent;
    &:after {
      transform: scaleX(1);
    }
  }
}
