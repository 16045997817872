// // // // VARIABLES

:root {
  // COLORS
  --c-white: #ffffff;
  --c-black: #040404;
  --c-blue: #16133e;
  --c-gray-dark: #1a1a1a;
  --c-green: #01ffb3;
  --c-purple: #976bf7;
  --c-text: #ffffff;
  --c-text-dark: #333333;
  --c-text-gray: #cdcdcd;
  --c-text-gray-dark: #818383;
  --c-border: #e5eaf4;
  --c-border-dark: #979797;

  // FONT
  --f-light: 300;
  --f-regular: 400;
  --f-semi-bold: 600;
  --f-bold: 700;

  // LAYOUT
  --l-header-height: 100px;
  --padding: 16px;
  --space: 16px;
}
