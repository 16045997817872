// // // // LAYOUT

body {
  position: relative;

  background-color: var(--c-black);
}

app-carousel [class$="__item"] {
  &:nth-child(n + 4) {
    display: none;
  }

  @include breakpoint(mobile) {
    &:nth-child(n + 4) {
      display: block;
    }
  }
}

.main-content {
  @include animation(fadeIn, 0.5s);
}

.section {
  padding: size(140) 0;

  &__title {
    margin-bottom: size(60);

    font-size: size(45);
    font-weight: var(--f-bold);
    text-transform: capitalize;
    color: var(--c-text);

    &__wrapper {
      .button {
        margin-top: size(60);
      }
    }
  }

  &__text {
    font-size: size(36);
    font-weight: var(--f-regular);
    color: var(--c-text-gray);
  }

  &__list-title {
    margin-top: size(60);

    font-family: "Roboto Mono", monospace;
    font-size: size(32);
    font-weight: var(--f-bold);
    color: var(--c-text);
  }

  &__list {
    &__item {
      position: relative;

      margin-top: size(20);
      padding-left: size(26);

      font-family: "Kayak Sans";
      font-size: size(24);
      font-weight: var(--f-light);
      color: var(--c-text-gray);

      &:before {
        position: absolute;
        top: 50%;
        left: 0;

        display: block;
        width: size(16);
        height: size(16);
        border-radius: 50%;

        background-color: var(--c-green);

        content: "";
        transform: translateY(-50%);
      }
    }
  }

  @include breakpoint(tablet) {
    padding: size(40);
  }

  @include breakpoint(mobile) {
    padding: size(40);

    &__title {
      font-size: size(24);

      &.--right {
        text-align: left;
      }
    }

    &__text {
      font-size: size(18);
    }

    &__list-title {
      margin-top: size(20);

      font-size: size(18);
    }

    &__list {
      &__item {
        padding-left: size(17);

        font-size: size(16);

        &:before {
          width: size(7);
          height: size(7);
        }
      }
    }

    &.--with-carousel {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@include breakpoint(tablet) {
  .intro {
    &__animation {
      display: none;
    }
  }
}
